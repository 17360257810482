import React, { useState } from "react";
import { Button, Modal } from "antd";
import logo from "../../../assets/images/logo.svg";
import { Tabs, Form, Input } from "antd";
import corporateIcon from "../../../assets/images/corporate-icon.svg";

function AllInvoices() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { TabPane } = Tabs;

    const onChange = (key) => {
        console.log(key);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="component-wrapper">
            <div className="d-flex align-item-center space-between mb-3">
                <div className="heading mb-0">
                    <h2>Invoice Details</h2>
                </div>
                <button className="btn btn-primary btn-sm">
                    Download Invoice
                </button>
            </div>
            <div className="vendorInvoice__wrapper">
                <div className="vendorInvoice__logo">
                    <img src={logo} alt="" />
                </div>
                <div className="vendorInvoice__detail">
                    <h2>Invoive From Pro Packaging</h2>
                    <p className="vendorInvoice__invo-num">
                        Invoice No # IN-123456
                    </p>

                    <div className="d-flex space-between">
                        <div className="vendorInvoice-one vendorInvoice-flex">
                            <p className="issueDate">
                                Approval Date : <span>__________</span>
                            </p>
                        </div>

                        <div className="vendorInvoice-three vendorInvoice-flex paymentDetail__Two">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Amount:</th>
                                        <td>
                                            <strong>40,000PKR</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td className="overdue">Overdue</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="approve-div d-flex align-item-center space-between">
                    <p className="approve-text">
                        Please approve the invoice its overdue by 15 days.
                    </p>
                    <p className="reminder">Send-Reminder</p>
                </div>
                <div className="invo__title">
                    <h2 className="dashboard-title mb-3">
                        <img src={corporateIcon} alt="" /> Line Items
                    </h2>
                </div>

                <div className="invoice__tabs">
                    <Tabs defaultActiveKey="1" onChange={onChange}>
                        <TabPane tab="Details" key="1">
                            <div class="inv-table">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Item</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">Unit Price</th>
                                            <th scope="col">Account</th>
                                            <th scope="col">Tax Rate</th>
                                            <th scope="col">Account (PKR)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Packaging Material</td>
                                            <td>Packaging Material, 12x14</td>
                                            <td>1000</td>
                                            <td>12.00</td>
                                            <td>300 - Raw Material</td>
                                            <td>Tax on Purchases (8.25%)</td>
                                            <td>600.00</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Material</td>
                                            <td>Packaging Material, 12x14</td>
                                            <td>1000</td>
                                            <td>12.00</td>
                                            <td>300 - Raw Material</td>
                                            <td>Tax on Purchases (8.25%)</td>
                                            <td>600.00</td>
                                        </tr>
                                        <tr>
                                            <td>Packaging Material</td>
                                            <td>Packaging Material, 12x14</td>
                                            <td>1000</td>
                                            <td>12.00</td>
                                            <td>300 - Raw Material</td>
                                            <td>Tax on Purchases (8.25%)</td>
                                            <td>600.00</td>
                                        </tr>
                                        <tr class="inv-total">
                                            <td colspan="5"></td>
                                            <td>Sub Total</td>
                                            <td>60,000.00</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>
                                                Includes Tax (Raw Material
                                                8.25%)
                                            </td>
                                            <td>72.00</td>
                                        </tr>
                                        <tr>
                                            <td colspan="5"></td>
                                            <td>
                                                Includes Tax (Finished Goods
                                                12%)
                                            </td>
                                            <td>7128.00</td>
                                        </tr>
                                        <tr class="inv-total">
                                            <td colspan="5"></td>
                                            <td>Total</td>
                                            <td>60,000.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </TabPane>
                        <TabPane tab="Log" key="2">
                            <div class="inv-table">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col">Action</th>
                                            <th scope="col">Date & Time</th>
                                            <th scope="col">User</th>
                                            <th scope="col">Comment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Invoice Issued</td>
                                            <td>20-Oct-2021; 18:06:25</td>
                                            <td>Muhammad Ahmed</td>
                                            <td>
                                                New invoice created with invoice
                                                number IN02156.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </TabPane>
                    </Tabs>
                    <form>
                        <div className="form-group ">
                            <Form.Item label="Comment" required={true}>
                                <Input placeholder="Enter Text" />
                            </Form.Item>
                        </div>
                    </form>

                    <div className="main-btn">
                        <button
                            className="btn btn-primary btn-sm mr-2"
                            onClick={showModal}
                        >
                            Reject Invoice
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={showModal}
                        >
                            Approve Invoice
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                title="Approve Invoice"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <button className="btn btn-primary btn-sm mr-2">
                        Reject Invoice
                    </button>,
                    <button className="btn btn-primary btn-sm mr-2">
                        Approve Invoice
                    </button>,
                ]}
            >
                <p>
                    Are you sure, you want to Approve invoice no,{" "}
                    <span class="modal-primary">IN -12345?</span>
                </p>
            </Modal>
        </div>
    );
}

export default AllInvoices;
