import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space } from "antd";
import { useState } from "react";
import { Drawer } from "antd";
import { Form, Select, Input } from "antd";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}
function Department() {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");

    const changeTitle = (title) => {
        setTitle(title);
    };

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const columns = [
        {
            title: "Departments",
            dataIndex: "departments",
            key: "departments",
            sorter: {
                compare: (a, b) => a.departments - b.departments,
                multiple: 4,
            },
        },
        {
            title: "Creation Date",
            dataIndex: "creationDate",
            key: "creationDate",
            sorter: {
                compare: (a, b) => a.creationDate - b.creationDate,
                multiple: 3,
            },
        },
        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            showDrawer();
                            changeTitle("Edit Department");
                        }}
                    >
                        <div className="table-edit">
                            <FontAwesomeIcon icon={faPencil} />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            departments: "Department 1",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
        {
            key: "2",
            departments: "Department 1",
            creationDate: "20-Oct-2021",
            tags: ["In-Active"],
        },
        {
            key: "3",
            departments: "Department 1",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
    ];

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Departments</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Vendors</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                        showDrawer();
                        changeTitle("Add Department");
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> Add Department
                </button>
            </div>
            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>

            <Drawer
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
            >
                <form className="form-row">
                    <div className="form-group ">
                        <Form.Item label="Name">
                            <Input placeholder="Name" />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <Form.Item label="Status" required={true}>
                            <Select placeholder="Select Status">
                                <Select.Option value="active">
                                    Active
                                </Select.Option>
                                <Select.Option value="inactive">
                                    In-Active
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="form-group main-btn">
                        <button className="btn btn-primary btn-sm">Save</button>
                    </div>
                </form>
            </Drawer>
        </div>
    );
}

export default Department;
