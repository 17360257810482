import React from "react";
import "./PaymentConfirm.css";
import logo from "../../../assets/images/logo.svg";
import { useNavigate } from "react-router-dom";

function PaymentConfirm() {
    const navigate = useNavigate();
    return (
        <div className="component-wrapper">
            <div className="heading mb-3">
                <h2>All Payments</h2>
            </div>
            <div className="vendorInvoice__wrapper">
                <div className="vendorInvoice__logo">
                    <img src={logo} alt="" />
                </div>
                <div className="payment-text d-flex">
                    <h2 className="mb-2">
                        You will be directed to JazzCash Interface, after
                        transaction is completed you will be redirected to
                        GenicPay.
                    </h2>
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => navigate("/payments")}
                    >
                        GenicPay
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PaymentConfirm;
