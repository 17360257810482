import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faPlus,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space } from "antd";
import { useNavigate } from "react-router-dom";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}
function Workflow() {
    const columns = [
        {
            title: "Workflow",
            dataIndex: "workflow",
            key: "workflow",
            sorter: {
                compare: (a, b) => a.workflow - b.workflow,
                multiple: 4,
            },
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            sorter: {
                compare: (a, b) => a.description - b.description,
                multiple: 3,
            },
        },
        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a>
                        <div className="table-edit">
                            <FontAwesomeIcon
                                icon={faEye}
                                onClick={toWorkflowDetail}
                            />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            workflow: "Invoice Creation & Approval",
            description:
                "Create invoice workflow and run them through a multi-step approval process",
            tags: ["Active"],
        },
        {
            key: "2",
            workflow: "Invoice Creation & Approval",
            description:
                "Create invoice workflow and run them through a multi-step approval process",
            tags: ["In-Active"],
        },
        {
            key: "3",
            workflow: "Invoice Creation & Approval",
            description:
                "Create invoice workflow and run them through a multi-step approval process",
            tags: ["Active"],
        },
    ];

    const navigate = useNavigate();
    const toWorkflowDetail = () => {
        navigate("/workflow-detail");
    };

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Work Flows</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Work Flows</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Activated Flows</span>
                    </div>
                </div>
            </div>

            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>
        </div>
    );
}

export default Workflow;
