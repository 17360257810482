import React from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Menu, Dropdown, Space } from "antd";
// import { DownOutlined } from '@ant-design/icons';

const menu = (
    <Menu
        items={[
            {
                label: (
                    <a href="/" onClick={(e) => e.preventDefault()}>
                        Home
                    </a>
                ),
                key: "0",
            },
            {
                label: (
                    <a href="/" onClick={(e) => e.preventDefault()}>
                        Logout
                    </a>
                ),
                key: "1",
            },
        ]}
    />
);

function Header() {
    return (
        <div className="header">
            <div className="header-wiget-wrapper">
                <div className="avatar-wrap">
                    <Dropdown overlay={menu} trigger={["click"]}>
                        <a href="/" onClick={(e) => e.preventDefault()}>
                            <Space>
                                <span className="avatar">
                                    <FontAwesomeIcon icon={faUser} />
                                </span>
                                <p>Super Admin</p>
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

export default Header;
