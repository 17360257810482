import React, { useState } from "react";
import { Button, Modal } from "antd";
import logo from "../../../assets/images/logo.svg";
import "./PaymentProcess.css";
import nift from "../../../assets/images/niftepay.png";
import easypaisa from "../../../assets/images/easypaisa.png";
import jazzcash from "../../../assets/images/jazzcash.png";
import offlineBooking from "../../../assets/images/offline-payment.png";
import { Form, Select, Input } from "antd";

function PaymentProcss() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = (e) => {
        e.preventDefault();
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    return (
        <div className="component-wrapper">
            <div className="d-flex align-item-center space-between mb-3">
                <div className="heading mb-0">
                    <h2>All Payments</h2>
                </div>
                <button className="btn btn-primary btn-sm">
                    Download Invoice
                </button>
            </div>
            <div className="vendorInvoice__wrapper">
                <div className="vendorInvoice__logo">
                    <img src={logo} alt="" />
                </div>
                <div className="vendorInvoice__detail">
                    <h2>Invoive Details</h2>
                    <p className="vendorInvoice__invo-num">
                        Invoice No # IN-123456
                    </p>

                    <div className="d-flex space-between">
                        <div className="d-flex paymentProcess__flex-row">
                            <div className="vendorInvoice-three vendorInvoice-flex paymentProcess__table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Approval Date:</th>
                                            <td>20-Oct-2021</td>
                                        </tr>
                                        <tr>
                                            <th>Payment ID</th>
                                            <td>PS68121</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="vendorInvoice-three vendorInvoice-flex paymentProcess__table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>Vendor Name:</th>
                                            <td>Pro-Packaging</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="vendorInvoice-three vendorInvoice-flex paymentProcess__three">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Amount:</th>
                                        <td>
                                            <strong>40,000PKR</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Status</th>
                                        <td className="pending">Pending</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="payment-process-1">
                    <h2 className="process-heading">Select Payment Method</h2>
                    <div className="online-method mt-4">
                        <div className="method-wrap">
                            <img src={nift} alt="niftepay" />
                        </div>
                        <div className="method-wrap">
                            <img src={easypaisa} alt="easypaisa" />
                        </div>
                        <div className="method-wrap">
                            <img src={jazzcash} alt="jazzcash" />
                        </div>
                    </div>
                </div>

                <div className="payment-process-2 mt-3">
                    <div className="d-flex space-between align-item-center">
                        <h2 className="process-heading">
                            Easypaisa Payment Process Details
                        </h2>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={showModal}
                        >
                            Initiate Payment
                        </button>
                    </div>
                    <div className="online-method mt-3">
                        <div className="payment-process-detail">
                            <ul>
                                <li>
                                    Please initiate the payment process by
                                    clicking on “Initiate Payment” button. After
                                    which you will be redirected to JazzCash
                                    payment merchant.
                                </li>
                                <li>
                                    There you will be given further instructions
                                    about how to complete your transaction.
                                </li>
                                <li>
                                    Once the transaction is completed, the
                                    payment status will be updated.
                                </li>
                                <li>
                                    In-case of any query or issue please contact
                                    us.
                                </li>
                            </ul>
                        </div>
                        <div className="method-wrap">
                            <img src={easypaisa} alt="easypaisa" />
                        </div>
                    </div>
                </div>

                <div className="offline-process mt-4">
                    <h2 className="process-heading">Offline Method</h2>
                    <div className="vendor-booking-img">
                        <img src={offlineBooking} alt="" />
                    </div>
                </div>
                <div className="vendor-booking-detail">
                    <h2 className="mb-3 mt-2">Vendor Baking Detailes</h2>
                    <form>
                        <div className="d-flex vendor-booking-form">
                            <div className="form-group ">
                                <Form.Item label="Select Bank" required={true}>
                                    <Select placeholder="Select Bank">
                                        <Select.Option value="hbl">
                                            HBL
                                        </Select.Option>
                                        <Select.Option value="faysal">
                                            Faysal Bank
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="form-group ">
                                <Form.Item label="IBAN" required={true}>
                                    <Input placeholder="Enter IBAN" />
                                </Form.Item>
                            </div>
                        </div>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={showModal}
                        >
                            Confirm Payment
                        </button>
                    </form>
                </div>
            </div>
            <Modal
                title="Approve Invoice"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <button className="btn btn-primary btn-sm mr-2">
                        Reject Invoice
                    </button>,
                    <button className="btn btn-primary btn-sm mr-2">
                        Approve Invoice
                    </button>,
                ]}
            >
                <p>
                    Are you sure, you want to Approve invoice no,{" "}
                    <span class="modal-primary">IN -12345?</span>
                </p>
            </Modal>
        </div>
    );
}

export default PaymentProcss;
