import React from "react";
import "./Corporation.css";
import corporateIcon from "../../../assets/images/corporate-icon.svg";
import { Tabs, Form, Input, Space, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Avatar from "../../../assets/images/emp-avatar.png";
import CircleMinus from "../../../assets/images/circle-minus.svg";

function Corporation() {
    const { TabPane } = Tabs;

    const onChange = (key) => {
        console.log(key);
    };
    const onFinish = (values) => {
        console.log("Received values of form:", values);
    };
    return (
        <div className="component-wrapper">
            <h2 className="dashboard-title mb-3">
                <img src={corporateIcon} alt="" /> Corporation Details
            </h2>
            <div className="corporate__wrapper">
                <div className="corporate__avatarRow">
                    <div className="d-flex">
                        <div className="corporate__avatar">
                            <img src={Avatar} alt="" />
                        </div>
                        <div className="corporate__detail">
                            <h2>ITGS Gropup</h2>
                            <p className="corporate__companyName">
                                Services Company
                            </p>
                            <div className="d-flex">
                                <div className="corporate__detail-left">
                                    <p>Karachi, Pakistan</p>
                                    <p className="corporate__detail-company">
                                        Company ID : ABCD-12354
                                    </p>
                                    <p>Sate of Onboarding : 1st jan 2022</p>
                                </div>
                                <div className="corporate__detail-right">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="genic__tabs">
                <Tabs defaultActiveKey="1" onChange={onChange}>
                    <TabPane tab="Profile" key="1">
                        <div className="d-flex">
                            <div className="profile__tabOne profile__tab">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Office
                                    Information
                                </h2>
                                <div className="corporate__detail-right profile__table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="profile__tabTwo profile__tab">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Bank
                                    Account Information
                                </h2>
                                <div className="corporate__detail-right profile__table">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                            <tr>
                                                <th>Respondant: </th>
                                                <td>John Deo</td>
                                            </tr>
                                            <tr>
                                                <th>Phone: </th>
                                                <td>+92 3456062607</td>
                                            </tr>
                                            <tr>
                                                <th>Email: </th>
                                                <td>john.doe@example.com</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab="Account Settings" key="2">
                        <div className="account-setting">
                            <h2 className="dashboard-title mb-3">
                                <img src={corporateIcon} alt="" /> Bank
                                Information
                            </h2>
                            <form className="form-row account__form">
                                <div className="d-flex">
                                    <div className="form-group">
                                        <Form.Item
                                            label="Bank Name:
                                            "
                                        >
                                            <Input
                                                placeholder="Enter Bank Name"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group ">
                                        <Form.Item label="Account Type:">
                                            <Input
                                                placeholder="Enter Account type"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="form-group">
                                        <Form.Item label="Account Title:">
                                            <Input
                                                placeholder="Enter Account Title"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group ">
                                        <Form.Item label="Account Number:">
                                            <Input
                                                placeholder="Enter Account Number"
                                                type="number"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="form-group">
                                        <Form.Item label="IBAN:">
                                            <Input
                                                placeholder="Enter IBAN Number"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group ">
                                        <Form.Item label="Email:">
                                            <Input
                                                placeholder="Enter Email"
                                                type="text"
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            </form>

                            <div className="account__moreFields-wrapper">
                                <h2 className="dashboard-title mb-3">
                                    <img src={corporateIcon} alt="" /> Office
                                    Information
                                </h2>
                                <Form
                                    name="dynamic_form_nest_item"
                                    onFinish={onFinish}
                                    autoComplete="off"
                                >
                                    <Form.List name="users">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(
                                                    ({
                                                        key,
                                                        name,
                                                        ...restField
                                                    }) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: "flex",
                                                                marginBottom: 8,
                                                            }}
                                                            align="baseline"
                                                        >
                                                            <div className="form-group">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "Respondant Name",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Missing Respondant Name",
                                                                        },
                                                                    ]}
                                                                    label="Respondant Name:"
                                                                >
                                                                    <Input placeholder="Enter Respondant Name" />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="form-group">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "Phone",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Missing Phone Number",
                                                                        },
                                                                    ]}
                                                                    label="Phone:"
                                                                >
                                                                    <Input
                                                                        placeholder="Enter Phone Number"
                                                                        type="number"
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            <div className="form-group">
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[
                                                                        name,
                                                                        "Email",
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                "Missing Email",
                                                                        },
                                                                    ]}
                                                                    label="Email:"
                                                                >
                                                                    <Input placeholder="Enter Email" />
                                                                </Form.Item>
                                                            </div>
                                                            <img
                                                                src={
                                                                    CircleMinus
                                                                }
                                                                alt=""
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                                className="circle-minus"
                                                            />
                                                        </Space>
                                                    )
                                                )}
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => add()}
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        Add field
                                                    </Button>
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            className="btn btn-primary btn-sm"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}

export default Corporation;
