import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space, DatePicker } from "antd";
import { useState } from "react";
import { Drawer } from "antd";
import { Form, Select, Input } from "antd";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}

function onChangeSlect(date, dateString) {
    console.log(date, dateString);
}

function Dashboard() {
    // const { Option, OptGroup } = Select;
    function handleChange(value) {
        console.log(`selected ${value}`);
    }

    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");

    const changeTitle = (title) => {
        setTitle(title);
    };

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            sorter: {
                compare: (a, b) => a.id - b.id,
                multiple: 4,
            },
            filters: [
                {
                    text: "2423523",
                    value: "2423523",
                },
                {
                    text: "242352",
                    value: "242352",
                },
            ],
        },
        {
            title: "Vendor",
            dataIndex: "vendor",
            key: "vendor",
            sorter: {
                compare: (a, b) => a.vendor - b.vendor,
                multiple: 3,
            },
        },
        {
            title: "Creation Date",
            dataIndex: "creationDate",
            key: "creationDate",
            sorter: {
                compare: (a, b) => a.creationDate - b.creationDate,
                multiple: 2,
            },
        },

        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            showDrawer();
                            changeTitle("Edit Contact Information");
                        }}
                    >
                        <div className="table-edit">
                            <FontAwesomeIcon icon={faPencil} />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            id: "2423523",
            vendor: "Javed Paints",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
        {
            key: "2",
            id: "2423523",
            vendor: "Javed Paints",
            creationDate: "20-Oct-2021",
            tags: ["In-Active"],
        },
        {
            key: "3",
            id: "2423523",
            vendor: "Javed Paints",
            creationDate: "20-Oct-2021",
            tags: ["Active"],
        },
    ];

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>Dashboard</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>7</h3>
                        <span>Users</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>7</h3>
                        <span>Vendors</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>7</h3>
                        <span>Invoices</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>7</h3>
                        <span>Payments</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                        showDrawer();
                        changeTitle("Add Contact Information");
                    }}
                >
                    <FontAwesomeIcon icon={faPlus} /> Add
                </button>
            </div>
            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>

            <Drawer
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
                width="50%"
            >
                <form className="form-row all-emp-form">
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item label="Contact Name" required={true}>
                                <Input placeholder="Contact Name" />
                            </Form.Item>
                        </div>
                        <div className="form-group ">
                            <Form.Item label="Company Name" required={true}>
                                <Input placeholder="Company Name" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item
                                label="Primary Contact Name"
                                required={true}
                            >
                                <Input
                                    placeholder="Primary Contact Name"
                                    type="text"
                                />
                            </Form.Item>
                        </div>
                        <div className="form-group ">
                            <Form.Item label="Phone Number" required={true}>
                                <Input
                                    placeholder="Phone Number"
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item label="Mobile Number" required={true}>
                                <Input
                                    placeholder="Mobile Number"
                                    type="number"
                                />
                            </Form.Item>
                        </div>
                    </div>

                    <h4 className="mb-2">Address Information</h4>
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item label="Address">
                                <Input placeholder="Address" type="text" />
                            </Form.Item>
                        </div>
                        <div className="form-group ">
                            <Form.Item label="State/Region" required={true}>
                                <Select placeholder="Select">
                                    <Select.Option value="sindh">
                                        Sindh
                                    </Select.Option>
                                    <Select.Option value="punjab">
                                        Punjab
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item label="County" required={true}>
                                <Select placeholder="Select">
                                    <Select.Option value="pakistan">
                                        Pakistan
                                    </Select.Option>
                                    <Select.Option value="uae">
                                        UAE
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="form-group ">
                            <Form.Item label="City" required={true}>
                                <Select placeholder="Select">
                                    <Select.Option value="karachi">
                                        Karachi
                                    </Select.Option>
                                    <Select.Option value="lahore">
                                        Lahore
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>

                    <h4 className="mb-2">Services / Product Information</h4>
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item label="Category" required={true}>
                                <Select placeholder="Select a category">
                                    <Select.Option value="supplier">
                                        Supplier
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="form-group ">
                            <Form.Item label="Type" required={true}>
                                <Select placeholder="Select a type">
                                    <Select.Option value="Ingenicotribe">
                                        Ingenicotribe
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="form-group ">
                            <Form.Item label="Select Date" required={true}>
                                <DatePicker placeholder="Select date" />
                            </Form.Item>
                        </div>
                        <div className="form-group ">
                            <Form.Item label="Details">
                                <Input placeholder="Details" type="text" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="form-group main-btn">
                        <button className="btn btn-primary btn-sm">Save</button>
                    </div>
                </form>
            </Drawer>
        </div>
    );
}

export default Dashboard;
