import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faSearch,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}
function Payment() {
    // const [visible, setVisible] = useState(false);
    // const [title, setTitle] = useState("");

    // const changeTitle = (title) => {
    //     setTitle(title);
    // };

    // const showDrawer = () => {
    //     setVisible(true);
    // };
    // const onClose = () => {
    //     setVisible(false);
    // };

    const columns = [
        {
            title: "Payment ID",
            dataIndex: "paymentId",
            key: "paymentId",
            sorter: {
                compare: (a, b) => a.paymentId - b.paymentId,
                multiple: 4,
            },
        },
        {
            title: "Invoice ID",
            dataIndex: "invoiceId",
            key: "invoiceId",
            sorter: {
                compare: (a, b) => a.invoiceId - b.invoiceId,
                multiple: 3,
            },
        },
        {
            title: "Approved On",
            dataIndex: "approvedOn",
            key: "approvedOn",
            sorter: {
                compare: (a, b) => a.approvedOn - b.approvedOn,
                multiple: 2,
            },
        },
        {
            title: "Amount (PKR)",
            dataIndex: "amount",
            key: "amount",
            sorter: {
                compare: (a, b) => a.amount - b.amount,
                multiple: 2,
            },
        },
        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a>
                        <div className="table-edit">
                            <FontAwesomeIcon
                                icon={faEye}
                                onClick={toPaymentDetail}
                            />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            paymentId: "VD-12345",
            invoiceId: "IN-12345",
            approvedOn: "javed Ali",
            amount: "1000",
            tags: ["Active"],
        },
        {
            key: "2",
            paymentId: "VD-12345",
            invoiceId: "IN-12345",
            approvedOn: "Hamid Naqvi",
            amount: "2000",
            tags: ["In-Active"],
        },
        {
            key: "3",
            paymentId: "VD-12345",
            invoiceId: "IN-12345",
            approvedOn: "Amir Kakar",
            amount: "3000",
            tags: ["Active"],
        },
    ];

    const navigate = useNavigate();
    const toPaymentDetail = () => {
        navigate("/payment-detail");
    };

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>All Payments</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Total Payments</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Pending</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Completed</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>In-Process</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Returned</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button className="btn btn-primary btn-sm">
                    <FontAwesomeIcon icon={faSearch} /> Search Invoice
                </button>
            </div>

            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>
        </div>
    );
}

export default Payment;
