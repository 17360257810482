import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserShield,
    faPencil,
    faSearch,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Tag, Space } from "antd";
import { useState } from "react";
import { Drawer } from "antd";
import { Form, Select, Input } from "antd";
import { useNavigate } from "react-router-dom";

function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
}
function Invoices() {
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState("");

    const changeTitle = (title) => {
        setTitle(title);
    };

    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    const columns = [
        {
            title: "Vendor ID",
            dataIndex: "vendorId",
            key: "vendorId",
            sorter: {
                compare: (a, b) => a.vendorId - b.vendorId,
                multiple: 4,
            },
        },
        {
            title: "Invoice ID",
            dataIndex: "invoiceId",
            key: "invoiceId",
            sorter: {
                compare: (a, b) => a.invoiceId - b.invoiceId,
                multiple: 3,
            },
        },
        {
            title: "Vendor Name",
            dataIndex: "vendorName",
            key: "vendorName",
            sorter: {
                compare: (a, b) => a.vendorName - b.vendorName,
                multiple: 2,
            },
        },
        {
            title: "Invoice Balance",
            dataIndex: "invoiceBalance",
            key: "invoiceBalance",
            sorter: {
                compare: (a, b) => a.invoiceBalance - b.invoiceBalance,
                multiple: 2,
            },
        },
        {
            title: "Status",
            key: "tags",
            dataIndex: "tags",
            render: (tags) => (
                <>
                    {tags.map((tag) => {
                        let color = tag.length > 5 ? "red" : "green";
                        if (tag === "Active") {
                            color = "green";
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
            sorter: {
                compare: (a, b) => a.tags - b.tags,
                multiple: 1,
            },
        },
        {
            title: "Action",
            key: "action",
            render: () => (
                <Space size="middle">
                    <a
                        onClick={() => {
                            showDrawer();
                            changeTitle("Edit Invoice");
                        }}
                    >
                        <div className="table-edit">
                            <FontAwesomeIcon icon={faPencil} />
                        </div>
                    </a>
                    <a>
                        <div className="table-edit">
                            <FontAwesomeIcon
                                icon={faEye}
                                onClick={toInvoiceDetail}
                            />
                        </div>
                    </a>
                </Space>
            ),
        },
    ];

    const data = [
        {
            key: "1",
            vendorId: "VD-12345",
            invoiceId: "IN-12345",
            vendorName: "javed Ali",
            invoiceBalance: "1000",
            tags: ["Active"],
        },
        {
            key: "2",
            vendorId: "VD-12345",
            invoiceId: "IN-12345",
            vendorName: "Hamid Naqvi",
            invoiceBalance: "2000",
            tags: ["In-Active"],
        },
        {
            key: "3",
            vendorId: "VD-12345",
            invoiceId: "IN-12345",
            vendorName: "Amir Kakar",
            invoiceBalance: "3000",
            tags: ["Active"],
        },
    ];

    const navigate = useNavigate();
    const toInvoiceDetail = () => {
        navigate("/invoice-detail");
    };

    return (
        <div className="component-wrapper">
            <div className="heading">
                <h2>All Invoices</h2>
            </div>
            <div className="card-wrapper">
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Total Invoices</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Pending Invoices</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Approves Invoices</span>
                    </div>
                </div>
                <div className="card">
                    <div className="card-icon">
                        <div className="icon-emp">
                            <FontAwesomeIcon icon={faUserShield} />
                        </div>
                    </div>
                    <div className="card-detail">
                        <h3>05</h3>
                        <span>Rejected Invoices</span>
                    </div>
                </div>
            </div>
            <div className="main-btn">
                <button
                    className="btn btn-primary btn-sm mr-2"
                    onClick={() => {
                        showDrawer();
                        changeTitle("Create Invoice");
                    }}
                >
                    Create Invoice
                </button>
                <button className="btn btn-primary btn-sm">
                    <FontAwesomeIcon icon={faSearch} /> Search Invoice
                </button>
            </div>

            <div className="my-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    onChange={onChange}
                    showSorterTooltip={false}
                />
            </div>

            <Drawer
                title={title}
                placement="right"
                onClose={onClose}
                visible={visible}
            >
                <form className="form-row">
                    <div className="form-group ">
                        <Form.Item label="Vendor ID">
                            <Input placeholder="Enter Vendor ID" />
                        </Form.Item>
                    </div>
                    <div className="form-group ">
                        <Form.Item label="Vendor Name">
                            <Input placeholder="Enter Vendor Name" />
                        </Form.Item>
                    </div>
                    <div className="form-group ">
                        <Form.Item label="Amount">
                            <Input placeholder="Enter Amount" type="number" />
                        </Form.Item>
                    </div>

                    <div className="form-group main-btn">
                        <button className="btn btn-primary btn-sm">Save</button>
                    </div>
                </form>
            </Drawer>
        </div>
    );
}

export default Invoices;
